import { Box, CircularProgress, Collapse } from '@mui/material';
import { useMobileMediaQuery } from 'hooks';
import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import VideoComposite, { VideoCompositeProps } from './VideoComposite';
import VideoNavBar, { VideoNavBarProps } from './VideoNavBar';
import VideoSideBar from './VideoSideBar';

export interface VideoRoomError {
  message: string;
  techMsg: string;
  httpCode?: string | number;
}
interface VideoRoomProps
  extends VideoNavBarProps,
    Partial<VideoCompositeProps> {
  sessionTitle: string;
  transitionSidebar?: boolean;
  loading?: boolean;
}

const VideoRoom: FC<VideoRoomProps> = ({
  sessionTitle,
  exitSessionLabel,
  relationshipId,
  scheduleSessionLabel,
  goalsLabel,
  chatLabel,
  callAdapter,
  onFetchUserImages,
  localeCode,
  transitionSidebar,
  loading,
}) => {
  const secondaryInstanceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const uuid = useRef<string>(Math.random().toString());
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();
  useEffect(() => {
    if (!isMobile) return;
    const channel = new BroadcastChannel('secondary-instance-check');
    channel.postMessage(uuid.current);
    channel.addEventListener('message', (msg) => {
      if (msg.data !== uuid.current && msg.data !== 'instance-already-open') {
        channel.postMessage('instance-already-open');
      }
      if (msg.data === 'instance-already-open') {
        if (secondaryInstanceTimerRef.current) {
          clearTimeout(secondaryInstanceTimerRef.current);
        }
        dispatch(
          showAppAlert({
            severity: 'error',
            message:
              'Only one Guider video can be open at a time. Redirecting to relationships page.',
            timeout: 10000,
          }),
        );
        secondaryInstanceTimerRef.current = setTimeout(() => {
          navigate(`/relationships`);
        }, 2000);
      }
    });
    return () => {
      channel.close();
    };
  }, []);
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '60px 1fr',
        height: '100dvh',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '1',
        }}
      >
        <VideoNavBar
          sessionTitle={sessionTitle}
          exitSessionLabel={exitSessionLabel}
          relationshipId={relationshipId}
          scheduleSessionLabel={scheduleSessionLabel}
          goalsLabel={goalsLabel}
          chatLabel={chatLabel}
        />
      </Box>
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '2',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!callAdapter || loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <VideoComposite
            callAdapter={callAdapter}
            localeCode={localeCode}
            sessionTitle={sessionTitle}
            onFetchUserImages={onFetchUserImages}
          />
        )}

        <Collapse in={transitionSidebar} orientation="horizontal">
          <VideoSideBar />
        </Collapse>
      </Box>
    </Box>
  );
};

export default VideoRoom;
