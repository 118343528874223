import {
  getFriendlyDateFormat,
  getLanguageFromCMS,
  getSubDomain,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IRelationship, ISession } from '@guider-global/shared-types';
import {
  AlertBox,
  ButtonStack,
  Icon,
  Stack,
  Text,
  theme,
} from '@guider-global/ui';

import History from '@mui/icons-material/History';
import {
  Box,
  Button,
  SxProps,
  Theme,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import { ModalCard } from 'components/ModalCard';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { deepMerge } from 'utils';

interface IRelationshipSessionsHistoryProps {
  relationship: IRelationship;
  handleClose: () => void;
  isGroupProgram: boolean;
  isActiveGroup: boolean;
  isGuide: boolean;
  timezone: string;
}

export const RelationshipSessionHistory: React.FC<
  IRelationshipSessionsHistoryProps
> = ({
  relationship,
  handleClose,
  isGroupProgram,
  isActiveGroup,
  isGuide,
  timezone,
}) => {
  //base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const isMobile = useMobileMediaQuery();
  const { pathname } = useLocation();

  // hooks
  const navigate = useNavigate();

  // Styling
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const declinedStyles: SxProps<Theme> = {
    border: 1,
    borderColor: combinedPalette.error.light,
    backgroundColor: 0,
    textDecoration: 'line-through',
  };
  const tentativeStyles: SxProps<Theme> = {
    border: 1,
    borderColor: combinedPalette.info.light,
    borderStyle: 'dashed',
    backgroundColor: 0,
  };

  const archived = relationship.isConcluded;

  const [isSessionsModalOpen, setIsSessionsModalOpen] = useState(false);

  const sessions =
    relationship.sessions?.filter((session) => !session.isArchived) ?? [];

  let parsedSessions: { [key: string]: ISession[] } = {};

  [...sessions]
    ?.sort((sessionA, sessionB) => {
      if (!sessionA.start || !sessionB.start) return 1;
      if (sessionA.start > sessionB.start) {
        return -1;
      } else {
        return 1;
      }
    })
    .forEach((session) => {
      if (!session?.start) {
        return;
      }

      const key = format(new Date(session.start), 'MMM yyyy');

      if (!parsedSessions[key]) {
        parsedSessions[key] = [];
      }

      parsedSessions[key].push(session as ISession);
    });

  const sessionCount = sessions.length;

  const locale = getLanguageFromCMS(baseLanguage?.language_code);

  const formattedTime = getTimeFormat(baseLanguage?.time_format);

  const formattedDate = getFriendlyDateFormat(baseLanguage?.date_format);

  const canLogSession =
    !relationship.isConcluded &&
    ((isGroupProgram && isActiveGroup && isGuide) || !isGroupProgram);

  useEffect(() => {
    if (pathname.includes('sessions') && !isSessionsModalOpen) {
      setIsSessionsModalOpen(true);
    }
  }, [pathname, isSessionsModalOpen]);

  const handleLogSession = async () => {
    navigate(`/relationships/${relationship.id}/sessions/log`, {
      replace: false,
    });
    setIsSessionsModalOpen(true);
  };

  const handleSessionClick = (sessionId: string) => {
    navigate(`/relationships/${relationship.id}/sessions/${sessionId}`, {
      replace: false,
    });

    setIsSessionsModalOpen(true);
  };

  const renderSessionMonths = () =>
    Object.entries(parsedSessions).map(([key, value]) => {
      return (
        <Box key={key} sx={{ mx: 2 }}>
          <Text text={key}></Text>
          <Box>{renderSessions(value)}</Box>
        </Box>
      );
    });

  const renderSessions = (sessionsToRender: ISession[]) => {
    return sessionsToRender?.map((session, index) => {
      const sessionDate =
        formatInTimeZone(
          new Date(session.start ?? ''),
          timezone,
          `${formattedDate} ${formattedTime}`,
          { locale },
        ) +
        '-' +
        formatInTimeZone(
          new Date(session.end ?? ''),
          timezone,
          ` ${formattedTime}`,
          { locale },
        );

      const sessionsLocation = () => {
        if (session.pastSession)
          return baseLanguage?.relationships?.session_history?.modal
            ?.logged_past_session_label;
        if (session.hasVideoConferencing) return session.videoConferencing;
        if (!session.hasVideoConferencing && !session.pastSession)
          return session.location;
      };

      const isDeclined =
        session.declinedEmails && session.declinedEmails?.length > 0;

      const isTentative =
        session.tentativeEmails && session.tentativeEmails?.length > 0;
      return (
        <Button
          data-cy={`relatioship-session-${session.id}`}
          key={`session-${session.id}`}
          sx={{
            width: { xs: '90vw', md: '652px' },
            height: '64px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            py: 1,
            px: 1.5,
            textAlign: 'left',
            textTransform: 'none',
            backgroundColor: '#F5F5F5',
            my: 1,
            '&:hover': {
              backgroundColor: '#E0E0E0',
            },
            ...(isDeclined ? declinedStyles : {}),
            ...(isTentative ? tentativeStyles : {}),
          }}
          disabled={archived}
          onClick={() => handleSessionClick(session.id || '')}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            {session.pastSession ? (
              <Icon
                Icon={History}
                size="xs"
                boxProps={{ padding: 0 }}
                color={combinedPalette.text.secondary}
              ></Icon>
            ) : (
              <></>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Text
                variant="body1"
                text={session.name}
                fontWeight={500}
                fontSize={'16px'}
              ></Text>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  mt: 0.5,
                  gap: 0.5,
                }}
              >
                {!session.pastSession ? (
                  <>
                    <Text
                      fontSize={'14px'}
                      fontWeight={500}
                      text={sessionDate}
                      variant="body2"
                      color={combinedPalette.info.main}
                    />
                    •
                    <Text
                      variant="body2"
                      fontSize={'14px'}
                      fontWeight={400}
                      text={sessionsLocation()}
                      color={combinedPalette.text.secondary}
                    ></Text>
                  </>
                ) : (
                  <Text
                    fontSize={'14px'}
                    fontWeight={400}
                    text={sessionsLocation()}
                    variant="body2"
                    color={combinedPalette.text.secondary}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Button>
      );
    });
  };

  const renderContent = () => {
    if (sessionCount === 0) {
      return (
        <Stack
          direction="column"
          justifyContent={'center'}
          alignItems="center"
          gap={3}
          height={isMobile ? '100%' : ''}
          pb={'50px'}
        >
          <AlertBox
            variant="iconWithBackground"
            iconVariant={'info'}
            title={
              baseLanguage?.relationships?.session_history?.modal?.empty_state
                ?.title
            }
            description={
              baseLanguage?.relationships?.session_history?.modal?.empty_state
                ?.description
            }
          />
          {canLogSession && (
            <ButtonStack
              width={isMobile ? '70%' : '50%'}
              spacing={1}
              direction="column"
              buttons={[
                {
                  key: 'log-session',
                  variant: 'outlined',
                  label:
                    baseLanguage?.globals?.sessions?.log_session_button_label,
                  onClick: () => handleLogSession(),
                  sx: { color: combinedPalette.text.secondary },
                  'data-cy':
                    'relationship-past-sessions-modal-log-session-button',
                },
              ]}
            />
          )}
        </Stack>
      );
    } else {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 2,
              minHeight: '300px',
              maxHeight: '400px',
              overflowY: 'scroll',
            }}
          >
            {renderSessionMonths()}
          </Box>
          {canLogSession && (
            <ButtonStack
              spacing={1}
              p={2}
              justifyContent="flex-end"
              flexDirection={isMobile ? 'column-reverse' : 'row'}
              direction={isMobile ? 'column' : 'row'}
              buttons={[
                {
                  key: 'log-session',
                  variant: 'text',
                  label:
                    baseLanguage?.globals?.sessions?.log_session_button_label,
                  sx: { color: combinedPalette.text.secondary },
                  onClick: () => handleLogSession(),
                  'data-cy':
                    'relationship-past-sessions-modal-log-session-button',
                },
              ]}
            />
          )}
        </>
      );
    }
  };

  return (
    <ModalCard
      title={baseLanguage?.relationships?.session_history?.modal?.title}
      description={
        baseLanguage?.relationships?.session_history?.modal?.description
      }
      handleClose={handleClose}
      childrenSx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      data-cy="relationship-past-sessions-modal"
      closeIconButtonDataCy="relationship-past-sessions-modal-close-icon-button"
    >
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        {renderContent()}
      </ThemeProvider>
    </ModalCard>
  );
};
