import {
  AvatarPersonaData,
  CallAdapter,
  CallComposite,
  darkTheme,
} from '@azure/communication-react';
import { PartialTheme } from '@fluentui/react';
import { useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';
import { LocaleCode, videoLocale } from '../VideoLocale';

export interface VideoCompositeProps {
  callAdapter: CallAdapter;
  localeCode?: string;
  sessionTitle?: string;
  onFetchUserImages?: (userId: string) => Promise<AvatarPersonaData>;
}

const VideoComposite: FC<VideoCompositeProps> = ({
  callAdapter,
  localeCode = 'en_GB',
  sessionTitle,
  onFetchUserImages,
}) => {
  const { startTimeISOString, trackScreenTime } = useMixpanelEvents();
  // Location
  const { pathname } = useLocation();

  // Styles
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const formFactor = isMobile ? 'mobile' : 'desktop';

  const defaultDarkTheme: PartialTheme = {
    ...darkTheme,
    components: {},
    defaultFontStyle: {
      ...darkTheme.defaultFontStyle,
      fontFamily: theme.typography.fontFamily,
    },
    palette: {
      ...darkTheme.palette,
    },
  };

  const typedLocaleCode = localeCode as LocaleCode;

  const configurationPageTitle = isMobile ? '' : sessionTitle;

  useEffect(() => {
    return () => {
      trackScreenTime('Video Session Duration', {
        Started: startTimeISOString,
      });
    };
  }, [startTimeISOString, trackScreenTime]);

  return (
    <CallComposite
      adapter={callAdapter}
      formFactor={formFactor}
      callInvitationUrl={pathname}
      onFetchAvatarPersonaData={onFetchUserImages}
      fluentTheme={defaultDarkTheme}
      locale={videoLocale({
        localeCode: typedLocaleCode,
        options: {
          strings: {
            call: { configurationPageTitle },
          },
        },
      })}
      options={{
        errorBar: true,
      }}
    />
  );
};

export default VideoComposite;
