import React, { useCallback, useEffect, useMemo } from 'react';
// external
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Box } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { connect, ConnectedProps } from 'react-redux';

// internal
import {
  getSubDomain,
  setDocAttrsAndMetaTag,
} from '@guider-global/front-end-utils';
import { getAgreementNames, reactPlugin } from 'utils';

// routes
import { CustomRoutes } from 'routes';

// components
import {
  AlertBox,
  Confetti,
  Footer,
  Loading,
  Markdown,
  NoNetwork,
  ScrollToTop,
} from 'components';

// modals
import { PolicyModal } from 'modals';

// store
import { RootState } from 'store';
import {
  hideAppError,
  hideNavbar,
  showAppAlert,
  showAppError,
  showNavbar,
  toggleModal,
} from 'store/slices/appSlice';

// hooks
import { useAuth } from '@guider-global/auth-hooks';
import { DatadogContext, useDatadog } from '@guider-global/datadog';
import { useUsers } from '@guider-global/front-end-hooks';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { GuiderSWRConfig } from '@guider-global/swr';
import { SetupWrapper } from 'components/SetupWrapper';
import { NavbarContainer, SurveyModals } from 'containers';
import { useLocalization } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';

const mapState = (state: RootState) => {
  return {
    modals: state.app.modals,
    isNavbarHidden: state.app.navbar.hidden,
    isAppLoading: state.app.loading,
    user: state.user,
    error: state.app.error,
  };
};

const mapDispatch = {
  toggleModal,
  showAppError,
  hideAppError,
  hideNavbar,
  showNavbar,
  showAppAlert,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const AppContainerComponent: React.FunctionComponent<PropsFromRedux> = ({
  isNavbarHidden,
  modals,
  toggleModal,
  isAppLoading,
  showAppError,
  showAppAlert,
  error,
}) => {
  // logging
  const { user } = useUsers({});
  const { sessionId } = useDatadog({ user });

  const sessionIdMemo = useMemo(() => sessionId, [sessionId]);

  // Router
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Utils
  const organizationSlug = getSubDomain();

  // Hooks
  const { localeCode } = useLocalization(organizationSlug);
  // - Auth0
  const { isLoading, isAuthenticated } = useAuth({});
  // - Sanity base language hook
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const textDirection = baseLanguage?.text_direction ?? 'ltr';
  const privacyPolicy = baseLanguage?.legal_notices?.privacy_policy;
  const termsOfService = baseLanguage?.legal_notices?.terms_of_service;
  const closeButtonLabel =
    baseLanguage?.globals?.common?.close_button_label ?? 'Close';

  // - useSanityOrganizationHook
  const { isLoadingOrganization, organization } = useOrganization({
    organizationSlug,
  });

  const additionalAgreements =
    organization?.white_label?.additional_agreements ?? [];

  const getHideNavbar = useCallback((pathname: string) => {
    // TODO extend this list with all the pages that should not have a navbar
    const hideNavbarList = ['complete-profile', 'login', 'error', 'video'];
    const splitPathname = pathname.split('/');
    return splitPathname.some((item) => hideNavbarList.includes(item));
  }, []);

  const getHideFooter = useCallback((pathname: string) => {
    // TODO extend this list with all the pages that should not have a footer
    const hideFooterList = ['login', 'error'];
    const splitPathname = pathname.split('/');
    return splitPathname.some((item) => hideFooterList.includes(item));
  }, []);

  const hideNav = () => {
    if (isNavbarHidden) return true;
    if (getHideNavbar(pathname)) return true;
    if (isAppLoading) return true;
    return false;
  };
  const renderFooter = () => {
    if (error.visible) return;
    if (getHideFooter(pathname)) return;

    return <Footer />;
  };

  const loading = isLoading || isLoadingOrganization;

  useEffect(() => {
    setDocAttrsAndMetaTag({ localeCode, textDirection });
  }, [localeCode, textDirection]);

  return (
    <GuiderSWRConfig
      value={{
        errorRetryCount: 3,
        onError: (error, key) => {
          const url = key.match(/#url:"([^"]*)"/);
          if (error.code === 'AUTH_EXPIRED') {
            console.warn('User token expired', url);
          } else if (error.response?.status !== 401) {
            console.error('SWR error thrown', error);
            showAppAlert({
              severity: 'error',
              message: error.message,
              timeout: 10000,
            });
          } else {
            showAppAlert({
              severity: 'error',
              message: 'Unauthorized',
              timeout: 10000,
            });
            navigate('/');
          }
        },
      }}
    >
      <Confetti />
      <ScrollToTop />
      <Loading />
      <DatadogContext.Provider value={{ sessionId: sessionIdMemo }}>
        <SetupWrapper organizationSlug={organizationSlug}>
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              flexGrow: 1,
              minHeight: '100vh',
            }}
          >
            <NavbarContainer hidden={hideNav() || isLoading} />
            <CustomRoutes />
            <PolicyModal
              isOpen={modals.termsOfService}
              modalClose={() => toggleModal('termsOfService')}
              policyTitle={
                termsOfService?.terms_of_service_label ?? 'Terms of Service'
              }
              policyContent={
                <Box
                  component={PortableText}
                  value={termsOfService?.terms_of_service_content ?? []}
                />
              }
              buttonLabel={closeButtonLabel}
            />
            <PolicyModal
              isOpen={modals.privacyPolicy}
              modalClose={() => toggleModal('privacyPolicy')}
              policyTitle={privacyPolicy?.privacy_policy_label}
              policyContent={
                <Box
                  component={PortableText}
                  value={privacyPolicy?.privacy_policy_content ?? []}
                />
              }
              buttonLabel={closeButtonLabel}
            />
            {!loading &&
              additionalAgreements.map((agreement) => {
                const {
                  content,
                  name,
                  agreement_name: { current },
                  _key,
                } = agreement;

                const { customCheckboxName } = getAgreementNames({
                  name: current,
                  id: _key,
                });
                return (
                  <PolicyModal
                    key={agreement.name}
                    isOpen={modals[customCheckboxName] ?? false}
                    modalClose={() => toggleModal(customCheckboxName)}
                    policyTitle={name}
                    policyContent={
                      <Box>
                        <Markdown>{content}</Markdown>
                      </Box>
                    }
                    buttonLabel={closeButtonLabel}
                  />
                );
              })}
          </Box>
        </SetupWrapper>

        <AlertBox />
        <NoNetwork />
        {isAuthenticated && <SurveyModals />}
        {renderFooter()}
      </DatadogContext.Provider>
    </GuiderSWRConfig>
  );
};

export const AppContainer = withAITracking(
  reactPlugin,
  connector(AppContainerComponent),
);
