import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage, useSettings } from '@guider-global/sanity-hooks';
import { IProfile, IUser, ProgramType } from '@guider-global/shared-types';
import {
  ButtonStack,
  FormTextArea,
  InfoIcon,
  Stack,
  StarRating,
  StarRatingInputValue,
  SurveyModal,
  Text,
} from '@guider-global/ui';
import { Box, useTheme } from '@mui/system';
import { interpolate } from 'functions';
import { useLocalization, useReviews } from 'hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

interface ReviewDialogProps {
  open: boolean;
  onClose: () => void;
  guideProfile: Partial<IProfile>;
  profile: Partial<IProfile>;
  programType: Partial<ProgramType> | undefined;
  programSlug?: string;
}

type GuideReviewInput = {
  [key: string]: StarRatingInputValue;
};

const ReviewDialog = ({
  open,
  onClose,
  guideProfile,
  profile,
  programType,
  programSlug,
}: ReviewDialogProps) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { reqReviews, isMutatingReviews, isLoadingReviews } = useReviews({});

  const { settings } = useSettings({
    localeCode,
  });

  const reviewBaseLanguage = baseLanguage?.relationships?.review_guide;

  const programTypeText = programType?.program_type_text;

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<GuideReviewInput>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<GuideReviewInput> = async (data) => {
    let rating, reviewText;

    Object.entries(data).forEach(([_, { value }]) => {
      if (typeof value === 'number') {
        rating = value;
      } else {
        reviewText = value;
      }
    });

    const reviewsResult = await reqReviews({
      method: 'POST',
      url: `/reviews`,
      data: {
        reviewText,
        rating,
        organizationSlug,
        users: [guideProfile?.userId, profile?.userId] as Partial<IUser>[],
        authorProfile: profile?.id as Partial<IProfile>,
        reviewedProfile: guideProfile.id as Partial<IProfile>,
        ...(programSlug && {
          programSlug: programSlug,
        }),
      },
    });

    if (reviewsResult.status === 'success') {
      onClose();
      return dispatch(
        showAppAlert({
          severity: 'success',
          message: reviewBaseLanguage?.review_success_text,
          timeout: 5000,
        }),
      );
    } else {
      return dispatch(
        showAppAlert({
          severity: 'error',
          message: reviewsResult?.message,
          timeout: 5000,
        }),
      );
    }
  };

  return (
    <SurveyModal
      onClose={onClose}
      open={open}
      title={interpolate(
        reviewBaseLanguage?.modal_title,
        {
          firstName: guideProfile?.firstName,
        },
        '',
      )}
      contentSx={{ pt: 1 }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *:not(:last-of-type)': { marginBottom: 3 },
          '& > *:nth-last-of-type(2)': { marginBottom: 0 },
        }}
      >
        <Stack direction={'column'} gap={3} py={3}>
          <StarRating
            control={control}
            name="guide-rating"
            heading={interpolate(
              settings?.guider_surveys?.guide_feedback_survey
                ?.trainee_rating_question_title ?? '',
              {
                guideSingular: programTypeText?.common?.guide?.singular,
              },
            )}
            errorMessage={baseLanguage?.globals?.errors?.required_field}
          />
          <FormTextArea
            fieldType="text"
            label={interpolate(
              settings?.guider_surveys?.guide_feedback_survey
                ?.trainee_feedback_question_title ?? '',
              {
                guideFirstName: guideProfile?.firstName,
                programTypeVerb: programTypeText?.common?.verb,
                guideSingular: programTypeText?.common?.guide?.singular,
              },
            )}
            sx={{ py: 2 }}
            name={'guide-feedback'}
            placeholder={interpolate(
              settings?.guider_surveys.guide_feedback_survey
                .response_placeholder ?? '',
              { guideSingular: programTypeText?.common?.guide?.singular },
            )}
            control={control}
            errorMessage={baseLanguage?.globals?.errors?.required_field}
          />
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <InfoIcon size="small" />
          <Text
            color={palette.text.secondary}
            variant="body1"
            text={settings?.guider_surveys.guide_feedback_survey.disclaimer}
          />
        </Stack>
        <Box
          sx={{
            paddingY: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'sticky',
            left: 0,
            bottom: 0,
            backgroundColor: 'paper.background.primary',
          }}
        >
          <ButtonStack
            buttons={[
              {
                variant: 'outlined',
                color: 'info',
                key: '1',
                label: baseLanguage?.globals?.common?.close_button_label,
                onClick: onClose,
              },
              {
                variant: 'contained',
                color: 'info',
                key: '2',
                label: baseLanguage?.globals?.common?.submit_button_label,
                type: 'submit',
                onClick: () => onSubmit,
                disabled:
                  !isValid ||
                  isSubmitting ||
                  isLoadingReviews ||
                  isMutatingReviews,
                loading: isSubmitting || isLoadingReviews || isMutatingReviews,
              },
            ]}
            direction={'row'}
            width={'100%'}
            buttonsWidth="min-content"
            justifyContent={'space-between'}
          />
        </Box>
      </Box>
    </SurveyModal>
  );
};

export default ReviewDialog;
